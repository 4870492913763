import api from '~/services/api';
import { SelectProps } from '../protocols/SelectProps';

export default async function getEspecies(tipo_operacao: number) {
  const res = await api.get(`/nf-outras-operacoes/especie/${tipo_operacao}`);
  const { data, success, message } = res.data;
  if (!success) {
    throw new Error(message);
  }
  const options: SelectProps[] = data.map((item: any) => {
    return {
      ...item,
      value: item.cod_especie,
      label: `${item.des_especie} - ${item.des_doc_fiscal}`,
    };
  });
  return options;
}
