import api from '~/services/api';
import { SelectProps } from '../protocols/SelectProps';
import { SerieProps } from '../protocols/SeriesProps';

export default async function getSeries(cod_loja: number) {
  const res = await api.get(`/nf-outras-operacoes/serie/${cod_loja}`);
  const { data, success, message } = res.data;
  if (!success) {
    throw new Error(message);
  }
  const options: SelectProps[] = data.map((item: SerieProps) => {
    return {
      value: item.num_serie,
      label: item.num_serie,
    };
  });
  return options;
}
