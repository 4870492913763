import api from '~/services/api';
import { TributacoesProps } from '../protocols/TributacoesProps';

export default async function getTributacoes() {
  try {
    const res = await api.get('/tributacao');
    const { success, data, message } = res.data;
    if (!success) {
      throw new Error(message);
    }
    const options = data.map((item: TributacoesProps) => {
      return {
        ...item,
        value: item.cod_tributacao,
        label: `${item.cod_tributacao} - ${item.des_tributacao}`,
      };
    });
    return options;
  } catch (e: any) {
    console.error(e);
  }
}
