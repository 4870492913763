import { useState } from 'react';
import api from '~/services/api';
import { FornecedorProps } from '../protocols/FornecedorProps';

export default async function getFornecedor(cod_pessoa: number) {
  const res = await api.get(`/fornecedor/${cod_pessoa}`);
  const forncedor: FornecedorProps = res.data.data;

  return {
    label: `${forncedor.cod_pessoa} - ${forncedor.des_fornecedor} (${forncedor.num_cpf_cnpj})`,
    value: forncedor.cod_pessoa,
    cod_trib_simples: forncedor.cod_trib_simples,
    flg_optante_simples: forncedor.flg_optante_simples,
    des_uf: forncedor.des_uf,
    flg_fornecedor: forncedor.flg_fornecedor,
    cod_pessoa: forncedor.cod_pessoa,
    num_rg_ie: forncedor.num_rg_ie || 'ISENTO',
    num_cpf_cnpj: forncedor.num_cpf_cnpj,
    flg_nao_transf_pis_cofins: forncedor.flg_nao_transf_pis_cofins,
  };
}
