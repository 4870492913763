import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;
  input:disabled {
    cursor: not-allowed;
  }
  textarea:disabled {
    cursor: not-allowed;
  }
  #containe-busca-produto {
    padding: 0;
    margin: 0;
    background: red;

    button {
      width: 100%;
    }
  }
`;

export const ContainerLoader = styled.div`
  width: 100%;
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
