import api from '~/services/api';

interface getLogsProps {
  cod_xml?: number;
  num_chave_acesso?: string;
}
export default async function getLogs({
  cod_xml,
  num_chave_acesso,
}: getLogsProps) {
  const res = await api.get('/nf-outras-operacoes/logs', {
    params: {
      cod_xml,
      num_chave_acesso,
    },
  });
  const { data, success, message } = res.data;
  if (!success) {
    throw new Error(message);
  }
  return {
    rows: data,
    num_chave_acesso: data[0]?.num_chave_acesso,
  };
}
