import api from '~/services/api';
import { ItensFinaceiroProps } from '../protocols/ItensFinaceiroProps';
import { ParcelasFinanceiroProps } from '../protocols/ParcelasFinanceiroProps';

export default async function getParcelasFinanceiro(
  cod_seq_nf: number,
): Promise<ItensFinaceiroProps[]> {
  const res = await api.get(
    `/nf-outras-operacoes/parcelas-financeiro/${cod_seq_nf}`,
  );
  const { data, success, message } = res.data;
  if (!success) {
    throw new Error(message);
  }

  const regs: ItensFinaceiroProps[] = data.map(
    (item: ParcelasFinanceiroProps, index: number) => {
      return {
        ...item,
        num_registro: index,
        isDisabled: true,
        des_finalizadora: `${item.cod_finalizadora} - ${item.des_finalizadora}`,
        des_definicao: '2 - DIAS DA DATA - (DD)',
      };
    },
  );

  return regs;
}
