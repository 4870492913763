import api from '~/services/api';
import { CondicaoPessoaFornecedorProps } from '../protocols/CondicaoPessoaFornecedorProps';

async function calcDataCondicao(
  dtaEmissao: string,
  dtaEntrada: string,
  numCond: number,
  codCond: number,
) {
  const result = await api.get(`/condicao/${codCond || 2}`, {
    params: {
      dtaEmissao,
      dtaEntrada,
      numCondicao: numCond || 30,
      codCondicao: codCond || 2,
      diaBase: 0,
    },
  });
  return result.data?.data;
}
export default async function getCondicaoPessoaFornecedor(
  cod_pessoa_fornecedor: number,
  perfil_cod_cc: number,
  perfil_cod_categoria: number,
  cod_perfil: number,
  tipo_condicao: number,
  total: number,
  dtaEmissao: string,
  dtaEntrada: string,
): Promise<any[]> {
  const result: any[] = [];
  let num_registro = 1;
  let totalRegistro = 0;
  const res = await api.get(
    `/pessoa-condicao-fornecedor/${cod_pessoa_fornecedor}/${tipo_condicao}`,
  );
  const { data, success, message } = res.data;
  if (!success) {
    throw new Error(message);
  }

  if (data.length === 0) {
    const apiResponse = await api.get(`/busca-parcelas/${cod_perfil}`);
    const { data: dataPerfil, success: successPerfil } = apiResponse.data;

    if (!successPerfil) throw new Error('Erro ao buscar parcelas do perfil');
    totalRegistro = dataPerfil.length;
    // eslint-disable-next-line no-restricted-syntax
    for await (const item of dataPerfil) {
      const dta_vencimento = await calcDataCondicao(
        dtaEmissao,
        dtaEntrada,
        item.num_condicao,
        item.cod_condicao,
      );
      result.push({
        num_registro,
        num_condicao: item.num_condicao,
        des_definicao: `${item.des_definicao} - ${item.des_condicao}`,
        cod_condicao: item.cod_condicao,
        cod_finalizadora: item.cod_finalizadora,
        des_finalizadora: item.des_finalizadora,
        cod_cc: perfil_cod_cc,
        cod_categoria: perfil_cod_categoria,
        dta_vencimento,
        val_parcela: totalRegistro === 0 ? total : total / totalRegistro,
        flg_quitado: false,
      });
      num_registro += 1;
    }
    return result;
  }
  totalRegistro = data.length;
  // eslint-disable-next-line no-restricted-syntax
  for await (const item of data) {
    const dta_vencimento = await calcDataCondicao(
      dtaEmissao,
      dtaEntrada,
      item.num_condicao,
      item.cod_condicao,
    );
    result.push({
      num_registro,
      num_condicao: item.num_condicao,
      des_definicao: `${item.des_definicao} - ${item.des_condicao}`,
      cod_condicao: item.cod_condicao,
      cod_finalizadora: item.cod_finalizadora,
      des_finalizadora: item.des_finalizadora,
      cod_cc: perfil_cod_cc,
      cod_categoria: perfil_cod_categoria,
      dta_vencimento,
      val_parcela: totalRegistro === 0 ? total : total / totalRegistro,
      flg_quitado: false,
    });

    num_registro += 1;
  }

  return result;
}
