import api from '~/services/api';

export default async function getLoja(cod_Loja: number) {
  const { data } = await api.get(`/lojas/${cod_Loja}`);
  let props;
  if (data.success) {
    props = {
      cod_loja: data.data.cod_loja,
      des_loja: data.data.des_loja,
      label:
        data.data.cod_loja < 10
          ? `0${data.data.cod_loja} - ${data.data.des_loja} (${data.data.des_cidade})`
          : `${data.data.cod_loja} - ${data.data.des_loja} (${data.data.des_cidade})`,
      selected: true,
      uf: data.data.des_uf,
      value: data.data.cod_loja,
      num_cnpj: data.data.num_cnpj,
    };
  }
  return props;
}
