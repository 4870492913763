import api from '~/services/api';
import { errorHandler } from '~/utils/ErrorHandler';
import { FinalizadoraProps } from '../protocols/FinalizadoraProps';

export default async function getFinalizadoras() {
  try {
    const res = await api.get('/finalizadora');
    const { data, success, message } = res.data;
    if (!success) {
      throw new Error(message);
    }
    const options = data.map((item: FinalizadoraProps) => {
      return {
        value: item.cod_finalizadora,
        label: `${item.cod_finalizadora} - ${item.des_finalizadora}`,
      };
    });
    return options;
  } catch (e: any) {
    errorHandler(e);
  }
}
