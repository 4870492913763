import api from '~/services/api';
import { CFOPSProps } from '../protocols/CFOPSProps';

export default async function getCfops(codPerfil: number) {
  if (codPerfil) {
    try {
      const res = await api.get(`/perfil-cfop-cliC-fiscal/${codPerfil}`);
      const { success, data, message } = res.data;
      if (!success) {
        throw new Error(message);
      }
      const options = data.map((cfop: CFOPSProps) => {
        return {
          ...cfop,
          cfop: Number(cfop.cfop),
          value: cfop.cfop,
          label: `${cfop.cfop} - ${cfop.des_cfop}`,
        };
      });
      return options;
    } catch (e: any) {
      console.error(e);
    }
  }
}
