import api from '~/services/api';
import { errorHandler } from '~/utils/ErrorHandler';
import { CondicaoProps } from '../protocols/CondicaoProps';

export default async function getCondicoes() {
  try {
    const res = await api.get('/condicao');
    const { data, success, message } = res.data;
    if (!success) {
      throw new Error(message);
    }
    const options = data.map((item: CondicaoProps) => {
      return {
        value: item.cod_condicao,
        label: `${item.des_definicao} - ${item.des_condicao}`,
      };
    });
    return options;
  } catch (e: any) {
    errorHandler(e);
  }
}
