import api from '~/services/api';

export default async function getNcm(inputValue?: string) {
  const res = await api.get('/produto/ncms', {
    params: {
      filtro: inputValue === undefined ? '' : inputValue,
    },
  });
  const { data, success, message } = res.data;
  if (!success) {
    throw new Error(message);
  }
  const regs = data
    .filter((f: any) => f.flg_inativo !== true)
    .map((item: any) => {
      return {
        value: item.cod_ncm,
        label: item.descricao,
      };
    });
  return regs;
}
