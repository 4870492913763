/* eslint-disable no-self-compare */
/* eslint-disable no-shadow */
import api from '~/services/api';
import { ItensProps } from '../protocols/ItensProps';
import { CFOPSProps } from '../protocols/CFOPSProps';

export default async function getItens(
  cod_seq_nf: number,
  tributacoes?: any[],
  cfops?: CFOPSProps[],
  ncms?: any[],
) {
  const res = await api.get(`/nf-outras-operacoes/itens/${cod_seq_nf}`);
  const { data, success, message } = res.data;
  if (!success) {
    throw new Error(message);
  }
  const regs: ItensProps[] = data.map((item: any, index: number) => {
    const cfop =
      cfops === undefined
        ? undefined
        : cfops.find((i) => i?.cfop === item.cfop);

    const tributacao =
      tributacoes === undefined
        ? undefined
        : tributacoes.find((i) => i?.cod_tributacao === item.cod_tributacao);

    const ncm =
      ncms === undefined ? undefined : ncms.find((i) => i?.ncm === item.ncm);
    return {
      num_registro: index,
      ...item,
      isDisabled: true,
      val_inputs: {
        produto: {
          cod_produto: item.cod_produto,
          des_produto: item.des_produto,
          cod_ncm: item.cod_ncm,
        },
        tributacao,
        cfop,
        ncm,
        qtd_embalagem: item.qtd_embalagem,
        qtd_entrada: item.qtd_entrada,
        val_unitario: item.val_tabela,
        val_material: item.val_tabela_liq,
        val_desconto: item.val_desconto,
        val_acrescimo: item.val_despesa_acessoria,
        val_ipi: item.val_ipi,
        val_bc_st: item.val_icms_st,
        val_icms_st: item.val_icms_st,
        val_frete: item.val_frete,
        pis_cofins: item.pis_cofins,
        bonificado: false,
      },
    };
  });
  return regs;
}
