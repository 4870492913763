import api from '~/services/api';
import { TipoAjusteProps } from '../protocols/TipoAjusteProps';

export default async function getTipoAjustes(tipo_operacao: number) {
  try {
    const res = await api.get(
      `/nf-outras-operacoes/tipo-ajuste/${tipo_operacao}`,
    );
    const { success, data, message } = res.data;
    if (!success) {
      throw new Error(message);
    }
    const options = data.map((item: TipoAjusteProps) => {
      return {
        ...item,
        value: item.cod_tipo_ajuste,
        label: `${item.cod_tipo_ajuste} - ${item.des_ajuste}`,
      };
    });
    return options;
  } catch (e: any) {
    console.error(e);
  }
}
