import api from '~/services/api';
import { PerfilProps } from '../protocols/PerfilProps';

export default async function getPerfis(
  cod_loja: number,
  flg_fornecedor: boolean,
  flg_interestadual: boolean,
) {
  const res = await api.get(`/perfis-nf-outras-operacoes`, {
    params: {
      cod_loja,
      flg_fornecedor,
      flg_interestadual,
    },
  });
  const { data, success, message } = res.data;
  if (!success) {
    throw new Error(message);
  }
  const regs = data.map((item: any) => {
    const {
      cod_perfil,
      des_tipo_operacao,
      des_perfil,
      des_tipo_nf,
      des_tipo_unidade,
      des_tipo_emissao,
    } = item;

    return {
      value: item.cod_perfil,
      label: `${cod_perfil} -${des_tipo_operacao}${des_perfil} - Tipo: ${des_tipo_nf} | Un Emb: ${des_tipo_unidade} | Emiss: ${des_tipo_emissao}`,
      cod_categoria: item.cod_categoria,
      cod_cc: item.cod_cc,
      cod_condicao: item.cod_condicao,
      cod_finalizadora: item.cod_finalizadora,
      cod_perfil: item.cod_perfil,
      des_perfil: item.des_perfil,
      descricao: item.descricao,
      num_condicao: item.num_condicao,
      per_desconto: item.per_desconto,
      tipo_emissao: item.tipo_emissao,
      tipo_nf: item.tipo_nf,
      tipo_operacao: item.tipo_operacao,
      tipo_unidade: item.tipo_unidade,
      flg_gera_financeiro: item.flg_gera_financeiro,
    };
  });
  return regs;
}
